const api = {
	lambdaApi: {
		endpoint: '/.netlify/functions/', // endpoint is always root
		functions: {
			subsNewsletter: 'subsNewsletter'
		}
	}
}

export default api;
