// import 'cardbackImg' from '../assets/img/cards/generic/resized/cardback_normal_res.gif';
// import 'cardbackGoldImg' from '../assets/img/cards/generic/resized/cardback_gold_res.gif';
// import 'pepemanderImg' from '../assets/img/cards/gen1/resized/pepemander_res.gif';
// import 'pepertleImg' from '../assets/img/cards/gen1/resized/pepertle_res.gif';
// import 'pepesaurImg' from '../assets/img/cards/gen1/resized/pepesaur_res.gif';
// import 'pepechuImg' from '../assets/img/cards/gen1/resized/pepechu_res.gif';
// import 'goldenPepemanderImg' from '../assets/img/cards/gen1/resized/golden_pepemander_res.gif';
// import 'goldenPepesaurImg' from '../assets/img/cards/gen1/resized/golden_pepesaur_res.gif';
// import 'goldenPepertleImg' from '../assets/img/cards/gen1/resized/golden_pepertle_res.gif';
// import 'goldenPepechuImg' from '../assets/img/cards/gen1/resized/golden_pepechu_res.gif';
// import 'pepeKetchumImg' from '../assets/img/cards/gen1/resized/pepeketchum_res.gif';
// import 'wistyImg' from '../assets/img/cards/gen1/resized/wisty_res.gif';
// import 'boboRockImg' from '../assets/img/cards/gen1/resized/boborock_res.gif';
// import 'professorPedoImg' from '../assets/img/cards/gen1/resized/professorpedo_res.gif';
// import 'pigglypuffImg' from '../assets/img/cards/gen1/resized/pigglypuff_res.gif';
// import 'pigglytuffImg' from '../assets/img/cards/gen1/resized/pigglytuff_res.gif';
// import 'poffingImg' from '../assets/img/cards/gen1/resized/poffing_res.gif';
// import 'peezingImg' from '../assets/img/cards/gen1/resized/peezing_res.gif';
// import 'evoqubeImg' from '../assets/img/cards/items/resized/evoqube_res.gif';
// import 'goldenEvoqubeImg' from '../assets/img/cards/items/resized/golden_evoqube_res.gif';
// import 'goldenMicrochipImg' from '../assets/img/cards/items/resized/golden_microchip_res.gif';
// import 'goldenPepeketchumImg' from '../assets/img/cards/gen1/resized/golden_pepeketchum_res.gif';
// import 'pemeleonImg' from '../assets/img/cards/gen1/resized/pemeleon_res.gif';
// import 'goldenPemeleonImg' from '../assets/img/cards/gen1/resized/golden_pemeleon_res.gif';
// import 'warpertleImg' from '../assets/img/cards/gen1/resized/warpertle_res.gif';
// import 'goldenWarpertleImg' from '../assets/img/cards/gen1/resized/golden_warpertle_res.gif';
// import 'pastryImg' from '../assets/img/cards/newbeginning/optimized/pastry_opt.gif';
// import 'cerberyImg' from '../assets/img/cards/newbeginning/optimized/cerbery_opt.gif';
// import 'primarinyImg' from '../assets/img/cards/newbeginning/optimized/primariny_opt.gif';
// import 'unifairyImg' from '../assets/img/cards/newbeginning/optimized/unifairy_opt.gif';
// import 'witchenryImg' from '../assets/img/cards/newbeginning/optimized/witchenry_opt.gif';
// import 'cosmonyImg' from '../assets/img/cards/newbeginning/optimized/cosmony_opt.gif';
// import 'pivysaurImg' from '../assets/img/cards/gen1/resized/pivysaur_res.gif';
// import 'goldenPivysaurImg' from '../assets/img/cards/gen1/resized/golden_pivysaur_res.gif';
// import 'pepekarpImg' from '../assets/img/cards/gen1/resized/pepekarp.gif';
// import 'drukyChristmasImg' from '../assets/img/cards/gen1/resized/druky_christmas.gif';
// import 'fafnyChristmasImg' from '../assets/img/cards/gen1/resized/fafny_christmas.gif';
// import 'sairyChristmasImg' from '../assets/img/cards/gen1/resized/sairy_christmas.gif';
// import 'pyaradosImg' from '../assets/img/cards/gen1/resized/pyarados.gif';
// import 'drukyImg' from '../assets/img/cards/gen1/resized/druky.gif';
// import 'fafnyImg' from '../assets/img/cards/gen1/resized/fafny.gif';
// import 'sairyImg' from '../assets/img/cards/gen1/resized/sairy.gif';
// import 'venumuImg' from '../assets/img/cards/gen1/resized/venumu.gif';
// import 'kirimuImg' from '../assets/img/cards/gen1/resized/kirimu.gif';
// import 'shapuImg' from '../assets/img/cards/gen1/resized/shapu.gif';
// import 'legendaryPepeketchumImg' from '../assets/img/cards/one/pepeketchum.gif';
// import 'fudizardImg' from '../assets/img/cards/gen1/fudizard.gif';
// import 'goldenFudizardImg' from '../assets/img/cards/gen1/golden_fudizard.gif';
// import 'moltryImg' from '../assets/img/cards/newbeginning/moltry.gif';
// import 'rektoiseImg' from '../assets/img/cards/gen1/rektoise.gif';
// import 'goldenRektoiseImg' from '../assets/img/cards/gen1/golden_rektoise.gif';
// import 'pepeStreetBetsImg' from '../assets/img/cards/one/pepestreetbets.gif';
// import 'pastryPixelImg' from '../assets/img/cards/newbeginning/pixel/pastry.gif';
// import 'cerberyPixelImg' from '../assets/img/cards/newbeginning/pixel/cerbery.gif';
// import 'primarinyPixelImg' from '../assets/img/cards/newbeginning/pixel/primariny.gif';
// import 'unifairyPixelImg' from '../assets/img/cards/newbeginning/pixel/unifairy.gif';
// import 'dumpesaurImg' from '../assets/img/cards/gen1/dumpesaur.gif';
// import 'goldenDumpesaurImg' from '../assets/img/cards/gen1/golden_dumpesaur.gif';
// import 'pyepeddosImg' from '../assets/img/cards/one/pyepeddos.gif';
// import 'witchenryPixelImg' from '../assets/img/cards/newbeginning/pixel/witchenry.gif';
// import 'polymonImg' from '../assets/img/cards/matic/polygonPepemon.png';
// import 'czmonImg' from '../assets/img/cards/bsc/czmon.gif';
// import 'bscPepesaurImg' from '../assets/img/cards/bsc/bsc_pepesaur.gif';
// import 'bscPepemanderImg' from '../assets/img/cards/bsc/bsc_pepemander.gif';
// import 'bscPepertleImg' from '../assets/img/cards/bsc/bsc_pepertle.gif';
// import 'bscPivysaurImg' from '../assets/img/cards/bsc/bsc_pivysaur.gif';
// import 'bscPemeleonImg' from '../assets/img/cards/bsc/bsc_pemeleon.gif';
// import 'bscWarpertleImg' from '../assets/img/cards/bsc/bsc_warpertle.gif';
// import 'bscDumpesaurImg' from '../assets/img/cards/bsc/bsc_dumpesaur.gif';
// import 'bscFudizardImg' from '../assets/img/cards/bsc/bsc_fudizard.gif';
// import 'bscRektoiseImg' from '../assets/img/cards/bsc/bsc_rektoise.gif';
// import 'fomochuImg' from '../assets/img/cards/gen1/fomochu.gif';
// import 'goldenFomochuImg' from '../assets/img/cards/gen1/golden_fomochu.gif';
// import 'degochuImg' from '../assets/img/cards/bsc/degochu.gif';
// import 'pepelonImg' from '../assets/img/cards/one/pepelon.gif';
// import 'pishyImg' from '../assets/img/cards/newbeginning/pishy.gif';
// import 'mermyImg' from '../assets/img/cards/newbeginning/mermy.gif';
// import 'metaphyImg' from '../assets/img/cards/newbeginning/metaphy.gif';
// import 'zaptryImg' from '../assets/img/cards/newbeginning/zaptry.gif';
// import 'yugipepeImg' from '../assets/img/cards/one/yugipepe.gif';
// import 'cosmonyPixelImg' from '../assets/img/cards/newbeginning/pixel/cosmony.gif';
// import 'nioctibYubImg' from '../assets/img/cards/one/nioctib_yub_opt.gif';
// import 'zhuRongImg' from '../assets/img/cards/one/zhurong_opt.gif';
// import 'goldenNioctibYubImg' from "../assets/img/cards/one/golden_nioctib-yub.gif";
// import 'dianmuImg' from '../assets/img/cards/one/dianmu_opt.gif';
// import 'goldenZhuRongImg' from "../assets/img/cards/one/golden_zhurong_opt.gif";
// import 'augencoreImg' from "../assets/img/cards/one/augencore_opt.gif";

/* CARDS
1 - Pepemander Basic *
2 - Pepertle Basic *
3 - Pepesaur Basic *
4 - Pepechu Basic *
5 - Pepemander Gold *
6 - Pepesaur Gold *
7 - Pepertle Gold *
8 - Pepechu Gold *
9 - Ash Ketchum ***
10 - Wisty ***
11 - Pepe Rock ***
12 - Professor Pedo ***
13 - Pigglypuff *
14 - Pigglytuff **
15 - Poffing *
16 - Weezing **
17 - Evolution Qube *
18 - Evolution Qube Gold *
19 - Microchip Gold *
20 - Golden Pepeketchum
21 - Pemeleon ***
22 - Golden Pemeleon ***
23 - Warpertle ***
24 - Golden Warpertle ***
25 - Pastry *
26 - Cerbery ***
27 - Primariny ***
28 - Unifairy ***
29 - Witchenry ***
30 - Cosmony ***
31 - Pivysaur ***
32 - Golden Pivysaur ***
33 - Pepekarp *
34 - Druky Christmas ***
35 - Fafny Christmas ***
36 - Sairy Christmas ***
37 - Pyarados ***
38 - Fafny ***
39 - Druky ***
40 - Sairy ***
41 - Venumu ***
42 - Kirimu ***
43 - Shapu ***
44 - Legendary Pepeketchum ****
45 - Fudizard ***
46 - Golden Fudizard ***
47 - Moltry ***
48 - Rektoise ***
49 - Golden Rektoise ***
50 - PepeStreetBets ***
51 - Pastry *
52 - Cerbery ***
53 - Primariny ***
54 - Unifairy ***
55 - Dumpesaur ****
56 - Golden Dumpesaur ****
57 - Pyepeddos **
58 - Witchenry ****
59 - Fomochu ***
60 - Golden Fomochu ***
61 - Pepelon
62 - Phishy
63 - Mermy
64 - Metaphy
65 - Zaptry (X)
66 - Yugipepe
67 - Zaptry
68 - Cosmony - pixel
69 - Niotic Yub
70 - Zhu Rong
71 - Golden Nioctic Yub
72 - Dianmu
73 - Golden Zhurong
74 - Augencore
 */

export const getCardInfo = (cardId: number, chainId?: number) => {
    if (chainId === 56) {
        switch(cardId) {
            case 0:
                return {
                    title: 'Pepemon Cardback',
                    img: 'cardbackImg',
                };
            case 1:
                return {
                    title: 'CZmon',
                    img: 'czmonImg',
                };
            case 2:
                return {
                    title: 'Never to be released',
                    img: 'cardbackGoldImg',
                }
            case 3:
                return {
                    title: 'Pepesaur',
                    img: 'bscPepesaurImg',
                }
            case 4:
                return {
                    title: 'Pepemander',
                    img: 'bscPepemanderImg',
                }
            case 5:
                return {
                    title: 'Pepertle',
                    img: 'bscPepertleImg',
                }
            case 6:
                return {
                    title: 'Pivysaur',
                    img: 'bscPivysaurImg',
                }
            case 7:
                return {
                    title: 'Pemeleon',
                    img: 'bscPemeleonImg',
                }
            case 8:
                return {
                    title: 'Warpertle',
                    img: 'bscWarpertleImg',
                }
            case 9:
                return {
                    title: 'Degochu',
                    img: 'degochuImg',
                }
            case 10:
                return {
                    title: 'Dumpesaur',
                    img: 'bscDumpesaurImg',
                }
            case 11:
                return {
                    title: 'Fudizard',
                    img: 'bscFudizardImg',
                }
            case 12:
                return {
                    title: 'Rektoise',
                    img: 'bscRektoiseImg',
                }
        }
    }

    if (chainId === 137) {
        switch(cardId) {
            case 1:
                return {
                    title: 'Polymon',
                    img: 'polymonImg',
                };
            case 2:
                return {
                    title: 'To be never released',
                    img: 'cardbackGoldImg',
                }
        }
    }

    switch(cardId) {
        case 0:
            return {
                title: 'Pepemon Cardback',
                img: 'cardbackImg',
            };
        case 1:
            return {
                title: 'Pepemander',
                img: 'pepemanderImg',
            };
        case 2:
            return {
                title: 'Pepertle',
                img: 'pepertleImg',
            };
        case 3:
            return {
                title: 'Pepesaur',
                img: 'pepesaurImg',
            };
        case 4:
            return {
                title: 'Pepechu',
                img: 'pepechuImg',
            };
        case 5:
            return {
                title: 'Golden Pepemander',
                img: 'goldenPepemanderImg',
            };
        case 6:
            return {
                title: 'Golden Pepesaur',
                img: 'goldenPepesaurImg',
            };
        case 7:
            return {
                title: 'Golden Pepertle',
                img: 'goldenPepertleImg',
            };
        case 8:
            return {
                title: 'Golden Pepechu',
                img: 'goldenPepechuImg',
            };
        case 9:
            return {
                title: 'Pepe Ketchum',
                img: 'pepeKetchumImg',
            };
        case 10:
            return {
                title: 'Wisty',
                img: 'wistyImg',
            };
        case 11:
            return {
                title: 'Bobo Rock',
                img: 'boboRockImg',
            };
        case 12:
            return {
                title: 'Professor Pedo',
                img: 'professorPedoImg',
            };
        case 13:
            return {
                title: 'Pigglypuff',
                img: 'pigglypuffImg',
            };
        case 14:
            return {
                title: 'Pigglytuff',
                img: 'pigglytuffImg',
            };
        case 15:
            return {
                title: 'Poffing',
                img: 'poffingImg',
            };
        case 16:
            return {
                title: 'Peezing',
                img: 'peezingImg',
            };
        case 17:
            return {
                title: 'Evolution Qube',
                img: 'evoqubeImg',
            };
        case 18:
            return {
                title: 'Golden Evolution Qube',
                img: 'goldenEvoqubeImg',
            };
        case 19:
            return {
                title: 'Golden Microchip',
                img: 'goldenMicrochipImg',
            };
        case 20:
            return {
                title: 'Golden Pepeketchum',
                img: 'goldenPepeketchumImg',
            };
        case 21:
            return {
                title: 'Pemeleon',
                img: 'pemeleonImg',
            };
        case 22:
            return {
                title: 'Golden Pemeleon',
                img: 'goldenPemeleonImg',
            };
        case 23:
            return {
                title: 'Warpertle',
                img: 'warpertleImg',
            };
        case 24:
            return {
                title: 'Golden Warpertle',
                img: 'goldenWarpertleImg',
            };
        case 25:
            return {
                title: 'Pastry',
                img: 'pastryImg',
            };
        case 26:
            return {
                title: 'Cerbery',
                img: 'cerberyImg',
            };
        case 27:
            return {
                title: 'Primariny',
                img: 'primarinyImg',
            };
        case 28:
            return {
                title: 'Unifairy',
                img: 'unifairyImg',
            };
        case 29:
            return {
                title: 'Witchenry',
                img: 'witchenryImg',
            };
        case 30:
            return {
                title: 'Cosmony',
                img: 'cosmonyImg',
            };
        case 31:
            return {
                title: 'Pivysaur',
                img: 'pivysaurImg',
            };
        case 32:
            return {
                title: 'Golden Pivysaur',
                img: 'goldenPivysaurImg',
            };
        case 33:
            return {
                title: 'Pepekarp',
                img: 'pepekarpImg',
            };
        case 34:
            return {
                title: 'Druky',
                img: 'drukyChristmasImg',
            };
        case 35:
            return {
                title: 'Fafny',
                img: 'fafnyChristmasImg',
            };
        case 36:
            return {
                title: 'Sairy',
                img: 'sairyChristmasImg',
            };
        case 37:
            return {
                title: 'Pyarados',
                img: 'pyaradosImg',
            };
        case 38:
            return {
                title: 'Fafny',
                img: 'fafnyImg',
            };
        case 39:
            return {
                title: 'Druky',
                img: 'drukyImg',
            };
        case 40:
            return {
                title: 'Sairy',
                img: 'sairyImg',
            };
        case 41:
            return {
                title: 'Venumu',
                img: 'venumuImg',
            };
        case 42:
            return {
                title: 'Kirimu',
                img: 'kirimuImg',
            };
        case 43:
            return {
                title: 'Shapu',
                img: 'shapuImg',
            };
        case 44:
            return {
                title: 'Legendary Pepeketchum',
                img: 'legendaryPepeketchumImg',
            };
        case 45:
            return {
                title: 'Fudizard',
                img: 'fudizardImg',
            };
        case 46:
            return {
                title: 'Golden Fudizard',
                img: 'goldenFudizardImg',
            };
        case 47:
            return {
                title: 'Moltry',
                img: 'moltryImg',
            }
        case 48:
            return {
                title: 'Rektoise',
                img: 'rektoiseImg',
            };
        case 49:
            return {
                title: 'Golden Rektoise',
                img: 'goldenRektoiseImg',
            };
        case 50:
            return {
                title: 'PepeStreetBets',
                img: 'pepeStreetBetsImg',
            }
        case 51:
            return {
                title: 'Pastry',
                img: 'pastryPixelImg',
            };
        case 52:
            return {
                title: 'Cerbery',
                img: 'cerberyPixelImg',
            };
        case 53:
            return {
                title: 'Primariny',
                img: 'primarinyPixelImg',
            };
        case 54:
            return {
                title: 'Unifairy',
                img: 'unifairyPixelImg',
            };
        case 55:
            return {
                title: 'Dumpesaur',
                img: 'dumpesaurImg',
            };
        case 56:
            return {
                title: 'Golden Dumpesaur',
                img: 'goldenDumpesaurImg',
            };
        case 57:
            return {
                title: 'PyepeDDos',
                img: 'pyepeddosImg',
            };
        case 58:
            return {
                title: 'Witchenry',
                img: 'witchenryPixelImg'
            };
        case 59:
            return {
                title: 'Fomochu',
                img: 'fomochuImg'
            };
        case 60:
            return {
                title: 'Golden Fomochu',
                img: 'goldenFomochuImg'
            };
        case 61:
            return {
                title: 'Pepelon',
                img: 'pepelonImg',
            };
        case 62:
            return {
                title: 'Pishy',
                img: 'pishyImg'
            };
        case 63:
            return {
                title: 'Mermy',
                img: 'mermyImg'
            };
        case 64:
            return {
                title: 'Metaphy',
                img: 'metaphyImg',
            };
        case 65:
            return {
                title: 'Zaptry',
                img: 'zaptryImg',
            }
        case 66:
            return {
                title: 'Yugipepe',
                img: 'yugipepeImg',
            }
        case 67:
            return {
                title: 'Zaptry',
                img: 'zaptryImg',
            }
        case 68:
            return {
                title: "Cosmony", //pixel
                img: 'cosmonyPixelImg',
            }
        case 69:
            return {
                title: "Nioctib Yub",
                img: 'nioctibYubImg',
            }
        case 70:
            return {
                title: "Zhu Rong",
                img: 'zhuRongImg',
            }
        case 71:
            return {
                title: "Golden Nioctib Yub",
                img: 'goldenNioctibYubImg',
            }
        case 72:
            return {
                title: "Dianmu",
                img: 'dianmuImg',
            }
        case 73:
            return {
                title: "Golden Zhu Rong",
                img: 'goldenZhuRongImg',
            }
        case 74:
            return {
                title: "Augencore",
                img: 'augencoreImg',
            }
    }
}
